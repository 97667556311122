<template>
  <div class="w-100 d-flex justify-content-end">
    <button-dashed
      type="button"
      style="width: 36px; height: 36px; padding: 0"
      @click="pushToAudits(inventory, $event)"
    >
      <feather-icon
        v-b-tooltip.noninteractive.hover
        icon="LHandsIcon"
        size="16"
        class="cursor-pointer feather-trash-icon defaultIconColorTwo"
        :title="$t('Audit Table')"
      />
    </button-dashed>
    <button-dashed
      type="button"
      style="width: 36px; height: 36px; padding: 0"
      @click="showProduct(inventory.product_id, $event)"
    >
      <feather-icon
        v-b-tooltip.noninteractive.hover
        icon="LEyeIcon"
        size="16"
        class="cursor-pointer feather-trash-icon defaultIconColorTwo"
        :title="$t('Show the product')"
      />
    </button-dashed>
    <button-dashed style="width: 36px; height: 36px; padding: 0">
      <feather-icon
        v-b-tooltip.noninteractive.hover
        icon="LInOutIcon"
        size="16"
        class="cursor-pointer feather-trash-icon defaultIconColorTwo"
        :title="$t('Create a permanent transfer Order')"
        @click="gotoNewPage({name: 'home-inventory-transfer-order-by-asset-id', params: {id: inventory.id, product_id: inventory.product_id}}, $event)"
      />
    </button-dashed>
    <button-dashed style="width: 36px; height: 36px; padding: 0">
      <feather-icon
        v-b-tooltip.noninteractive.hover
        icon="LRepairIcon"
        size="16"
        class="cursor-pointer feather-trash-icon defaultIconColorTwo"
        :title="$t('Create a service order')"
        @click="navigateToServiceOrders(inventoryProduct)"
      />
    </button-dashed>
    <button-dashed style="width: 36px; height: 36px; padding: 0">
      <feather-icon
        v-b-tooltip.noninteractive.hover
        icon="LSignalIcon"
        size="16"
        class="cursor-pointer feather-trash-icon defaultIconColorTwo"
        :title="$t('Show asset reports (will take to “reports”)')"
        @click="gotoNewPage({name: 'home-inventory-catalog-item-audit', params: {id: inventory.product_id}}, $event)"
      />
    </button-dashed>
    <button-dashed
      v-if="$can('change', 'Main') && organizationRequiresEditConfirmation"
      style="width: 36px; height: 36px; padding: 0"
      @click="setIsEditable"
    >
      <feather-icon
        v-b-tooltip.noninteractive.hover
        :icon="isEditable ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
        size="36"
        class="cursor-pointer"
        :title="$t('Edit')"
      />
    </button-dashed>
    <button-dashed
      style="width: 36px; height: 36px; padding: 0"
      @click="trashButtonHandler"
    >
      <feather-icon
        v-b-tooltip.noninteractive.hover
        icon="LTrashIcon"
        size="16"
        class="cursor-pointer feather-trash-icon defaultIconColorTwo"
        :title="$t('Set Inactive')"
      />
    </button-dashed>
    <feather-icon
      v-if="inventory.allowed_to_sell"
      v-b-tooltip.noninteractive.hover
      icon="LPOIcon"
      :title="$t('Create a RFQ to all registered suppliers')"
      class="cursor-pointer"
      size="36"
      @click="navigateToPo(inventoryProduct.id, $event, 'home-inventory-item-quote')"
    />
  </div>
</template>

<script>
// Components
import { VBTooltip } from 'bootstrap-vue'
import ButtonDashed from '@core/components/b-button-dashed/ButtonDashed.vue'
import store from '@/store'
import { assetStates } from '@/enum/asset-statuses'

export default {
  name: 'FormTop',
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    ButtonDashed,
  },
  props: {
    isEditable: {
      type: Boolean,
      require: false,
      default: false,
    },
    handleOpenModal: {
      type: Function,
      required: true,
    },
  },
  computed: {
    inventory() {
      return this.$store.state[this.MODULE_NAME].inventory
    },
    inventoryProduct() {
      return this.$store.state[this.MODULE_NAME].inventoryForm.product
    },
    warehouse() {
      return this.$store.state.app.warehouse
    },
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
  },
  methods: {
    navigateToPo({ id }, e, name) {
      this.navigateTo({ inv_id: id }, e, name)
    },
    navigateTo(params, e, name) {
      if (e.ctrlKey) {
        const route = this.$router.resolve({
          name,
          params,
        })
        window.open(route.href)
      } else {
        this.$router.push({
          name,
          params,
        })
      }
    },
    showProduct(data, e) {
      if (e.ctrlKey) {
        const route = this.$router.resolve({ name: 'home-catalog-view', params: { id: data } })
        window.open(route.href)
      } else {
        this.$router.push({ name: 'home-catalog-view', params: { id: data } })
      }
    },
    navigateToServiceOrders(item) {
      const { product_id, status } = item
      if (status === assetStates.SERVICE.code) return
      this.showAssetToDamagedModal(item)
    },
    showAssetToDamagedModal({
      damaged_report_id, service_order_id,
    }) {
      if (damaged_report_id) {
        if (service_order_id) {
          this.$router.push({ name: 'request-service-quote-view', params: { id: service_order_id } })
        } else {
          this.$router.push({ name: 'home-orders-request-service-quote', params: { id: this.inventory.id } })
        }
      } else {
        this.$store.commit(`${this.MODULE_NAME}/SET_DAMAGED_INVENTORY_ITEM_ID`, this.inventory.id)
        this.$store.commit(`${this.MODULE_NAME}/SET_INVENTORIES_LIST_REF`, this.tableRef)
        this.handleOpenModal()
      }
    },
    pushToAudits(data, e) {
      const { id, product_id } = data

      if (e.ctrlKey) {
        const route = this.$router.resolve({
          name: 'home-inventory-item-audit',
          params: { id: product_id, inv_id: id },
        })
        window.open(route.href)
      } else {
        this.$router.push({
          name: 'home-inventory-item-audit',
          params: { id: product_id, inv_id: id },
        })
      }
    },
    setIsEditable() {
      this.$emit('changeEditAble')
      // this.isEdit = !this.isEdit
      // const { isEditable } = this.$store.state[this.MODULE_NAME]
      //
      // this.$store.commit(`${this.MODULE_NAME}/SET_IS_EDITABLE`, !isEditable)
    },
    trashButtonHandler() {
      const { id } = this.inventory
      this.confirmNotification(this, { ids: [id], active: !this.inventory.active }, `${this.MODULE_NAME}/statusChange`,
        { text: 'It is possible to revert this', confirmButtonText: 'Yes, deactivate it' })
        .then(
          () => {
            this.$router.push({ name: 'home-inventory-view', query: { location: `${this.warehouse.id}` } })
          },
        )
    },
  },
  setup() {
    const MODULE_NAME = 'inventories'

    return {
      MODULE_NAME,
    }
  },
}
</script>

<style lang="scss" scoped>
.l-quote-btn {
  background-color: #646464;
  padding: 7px;
  border-radius: 5px;
}
</style>
