<template>
  <div>
    <error-alert
      :error="error"
    />
    <b-form>
      <validation-observer
        ref="refInventoryCreate"
      >
        <inventory-update-form-top
          class="mb-1"
          :is-editable="isEditable"
          :handle-open-modal="handleModal"
          @changeEditAble="isEditAble"
        />
        <static-data class="mb-1" />
        <form-fields
          :is-editable="isEditable"
          :is-submit="isSubmit"
        />
      </validation-observer>
      <portal to="body-footer">
        <div class="d-flex mt-2 pb-1 justify-content-between">
          <div>
            <b-button
              v-if="!isFormChanged"
              class="saveBtn font-medium-1 font-weight-bolder"
              variant="outline-primary"
              :disabled="!isEditable"
              @click="clear"
            >
              {{ $t('Back to List') }}
            </b-button>
            <b-button
              v-else
              class="cancelBtn font-medium-1 font-weight-bolder"
              variant="outline-primary"
              @click="loader"
            >
              <feather-icon
                icon="LCancelIcon"
                size="16"
              />
              {{ $t('Cancel') }}
            </b-button>
          </div>
          <div
            class="d-flex"
            style="gap: 12px"
          >
            <b-button
              v-if="inventorySerializedAndNonSerializedVal"
              variant="secondary"
              class="saveBtn font-medium-1 font-weight-bolder d-flex align-items-center"
              style="gap: 10px"
              @click="printBarcode"
            >
              <Printer>
                <div
                  id="barcode"
                  hidden
                >
                  <div v-html="inventoryItemsBarcode" />
                </div>
              </Printer>
              <feather-icon
                icon="LPrintIcon"
                size="18"
              />
              {{ $t('Print Asset Tag') }}
            </b-button>
            <b-button
              variant="success"
              :disabled="isCreateForm ? false : !isFormChanged"
              class="saveBtn font-medium-1 font-weight-bolder"
              @click="submit"
            >
              {{ $t('SAVE') }}
            </b-button>
          </div>
        </div>
      </portal>
    </b-form>
    <b-s-warning-modal ref="confirm-modal" />
    <change-asset-to-damaged
      ref="change-asset-to-damaged"
    />
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'

// Components
import Printer from '@/views/components/Printer/Printer.vue'
import { BForm, BButton } from 'bootstrap-vue'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import { scrollToError } from '@core/utils/utils'
import config from '@/views/main/inventory/inventoryConfig'
import jsonToFormData from '@/libs/jsonToFormData'
import store from '@/store'
import ChangeAssetToDamaged
from '@/views/main/inventory/inventory-view/components/ChangeAssetToDamaged/ChangeAssetToDamaged.vue'
import InventoryUpdateFormTop from './FormTop.vue'
import StaticData from './StaticData.vue'
import FormFields from './FormFields.vue'

export default {
  name: 'InventoryUpdateForm',
  components: {
    ChangeAssetToDamaged,
    BForm,
    ErrorAlert,
    ValidationObserver,
    InventoryUpdateFormTop,
    StaticData,
    FormFields,
    BButton,
    Printer,
  },
  props: {
    loadData: {
      type: Function,
      default: () => {},
    },
    isCreateForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: {},
      onSubmit: false,
      isSubmit: false,
      isEditable: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  computed: {
    inventory() {
      return this.$store.state[this.MODULE_NAME].inventoryForm
    },
    inventorySerializedAndNonSerializedVal() {
      return this.$store.state[this.MODULE_NAME].inventorySerializedAndNonSerializedVal
    },
    getStockProduct() {
      return this.$store.state[this.MODULE_NAME].inventory
    },
    inventoryClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].inventoryFormClone
    },
    isFormChanged() {
      return JSON.stringify(this.inventory) !== this.inventoryClone
    },
    warehouse() {
      return this.$store.state.app.warehouse
    },
    inventoryItemsBarcode() {
      return this.$store.state[this.MODULE_NAME].inventoryItemsBarcode
    },
  },
  methods: {
    handleModal() {
      this.$refs['change-asset-to-damaged'].show()
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    },
    hasKey(key) {
      // eslint-disable-next-line no-prototype-builtins
      return this.updateFields.hasOwnProperty(key)
    },
    submit() {
      this.isSubmit = true
      function isNotNullOrEmpty(value) {
        if (this.hasKey('cost')) {
          return (
            value !== null && value !== undefined && value !== ''
          )
        }
        return true
      }
      const boundIsNotNullOrEmpty = isNotNullOrEmpty.bind(this)
      // Checking cost to feet custom error message
      this.error = {}
      this.onSubmit = true
      this.$refs.refInventoryCreate.validate().then(success => {
        if (success && boundIsNotNullOrEmpty(this.inventory.cost)) {
          const data = this.inventory
          const { id } = this.getStockProduct

          const mappedData = this.mappingFields(Object.keys(this.updateFields), data, {
            id,
            cost: +data.cost,
            sale_price: +data.sale_price,
            product_id: data.product.id,
            location: data.location,
            amount: data.amount,
            allowed_to_sell: +data.allowed_to_sell,
            active: +data.active,
            inspected: +data.inspected,
            condition_id: data.condition_id?.id,
          })

          mappedData.attachments = data.attachments.filter(attachment => !attachment.id)

          const toFormData = jsonToFormData(mappedData)

          this.sendNotification(this, toFormData, `${this.MODULE_NAME}/updateInventory`)
            .then(() => {
              this.$emit('load-data')
            }).catch(err => {
              this.error = err.response?.data
            })
        } else {
          scrollToError(this, this.$refs.refInventoryCreate)
        }
      })
    },
    clear() {
      this.$store.commit(`${this.MODULE_NAME}/SET_IS_EDITABLE`, true)
      this.$router.push({ name: 'home-inventory-view', query: { location: `${this.warehouse.id}` } })
    },
    printBarcode() {
      this.$htmlToPaper('barcode')
    },
    loader() {
      this.$refs.refInventoryCreate.reset()
      const clonedData = JSON.parse(this.inventoryClone)
      this.$store.commit(`${this.MODULE_NAME}/SET_INVENTORY_FORM`, clonedData)
    },
    isEditAble() {
      console.log(1)
      this.isEditable = !this.isEditable
    },
  },
  setup() {
    const MODULE_NAME = 'inventories'
    const MODULE_NAME_CLONE = 'cloneData'

    const { updateFields } = config()
    return {
      updateFields,
      MODULE_NAME,
      MODULE_NAME_CLONE,
    }
  },
}
</script>
