<template>
  <b-row>
    <b-col cols="6">
      <vue-tree-select :is-editable="isEditable" />
      <inventory-product-auto-suggest
        v-if="inventoryISSerialized"
        :is-editable="isEditable"
        class="mb-1"
      />
      <component
        :is="updateFields['serial_number'].type"
        v-if="inventoryISSerialized"
        v-model="inventory['serial_number']"
        v-bind="getProps('serial_number')"
        :is-editable="isEditable"
      />
      <component
        :is="updateFields[field].type"
        v-for="field in ['cost']"
        :key="field"
        v-model="inventory[field]"
        class="mb-0"
        v-bind="getProps(field)"
        :is-editable="isEditable"
      />
      <span
        v-if="isSubmit && inventory['cost'] == null && hasKey('cost')"
        class="text-danger"
      > {{ $t('The Cost field is required') }} </span>
      <!--Serialized-->
      <div class="mt-1">
        <span
          v-if="inventoryISSerialized"
          class="font-weight-bolder ml-1"
        > {{ $t('Status') }} </span>
      </div>
      <component
        :is="updateFields[field].type"
        v-for="field in ['active']"
        v-if="inventoryISSerialized"
        :key="field"
        v-model="inventory[field]"

        v-bind="getProps(field)"
        class="mb-0 ml-2 custom--inventory-checkbox"
        :is-editable="isEditable"
      />
      <component
        :is="updateFields[field].type"
        v-for="field in ['inspected']"
        v-if="inventoryISSerialized"
        :key="field"
        v-model="inventory[field]"
        v-bind="getProps(field)"
        class="mb-0 ml-2 custom--inventory-checkbox"
        :is-editable="isEditable"
      />
      <!--Serialized-->

      <component
        :is="updateFields['sale_price'].type"
        v-if="!inventoryISSerialized"
        v-model="inventory['sale_price']"
        v-bind="getProps('sale_price')"
        :is-editable="isEditable"
        :is-required-field="isRequired"
      />
      <!--Non Serialized-->
      <span
        v-if="!inventoryISSerialized"
        class="font-weight-bolder ml-1"
      > {{ $t('Status') }} </span>
      <component
        :is="updateFields[field].type"
        v-for="field in ['active']"
        v-if="!inventoryISSerialized"
        :key="field"
        v-model="inventory[field]"

        v-bind="getProps(field)"
        class="mb-0 ml-2 custom--inventory-checkbox"
        :is-editable="isEditable"
      />
      <component
        :is="updateFields[field].type"
        v-for="field in ['inspected']"
        v-if="!inventoryISSerialized"
        :key="field"
        v-model="inventory[field]"
        v-bind="getProps(field)"
        class="mb-0 ml-2 custom--inventory-checkbox"
        :is-editable="isEditable"
      />
      <component
        :is="updateFields[field].type"
        v-for="field in ['allowed_to_sell']"
        v-if="!inventoryISSerialized"
        :key="field"
        v-model="inventory[field]"
        v-bind="getProps(field)"
        class="mb-0 ml-2 custom--inventory-checkbox"
        :is-editable="getStockProduct.can_be_sold ? isEditable: !getStockProduct.can_be_sold"
        @input="allowedToSell"
      />
      <!-- Non Serialized-->

    </b-col>
    <b-col cols="6">
      <component
        :is="updateFields['tags'].type"
        v-if="hasKey('tags')"
        ref="tags_ref"
        v-model="inventory['tags']"
        v-bind="getProps('tags')"
        :selected-values="returnTags('tags')"
        :is-editable="isEditable"
      />
      <component
        :is="updateFields['comments'].type"
        v-model="inventory['comments']"
        v-bind="getProps('comments')"
        :is-editable="isEditable"
      />
      <component
        :is="updateFields['condition_id'].type"
        v-if="inventoryISSerialized"
        v-model="inventory['condition_id']"
        v-bind="getProps('condition_id')"
        :is-editable="isEditable"
      />
      <upload-attachment
        v-if="inventoryISSerialized"
        :is-editable="isEditable"
        :accept-extension-array="['.jpg', '.jpeg', '.png', '.tif', '.pdf', '.bmp']"
      />
      <span
        v-if="inventoryISSerialized"
        class="font-weight-bolder ml-1"
      > {{ $t('Sales') }} </span>
      <component
        :is="updateFields[field].type"
        v-for="field in ['allowed_to_sell']"
        v-if="inventoryISSerialized"
        :key="field"
        v-model="inventory[field]"
        v-bind="getProps(field)"
        class="mb-0 custom--inventory-checkbox"
        :is-editable="getStockProduct.product.detail.can_be_sold ? isEditable: !getStockProduct.product.detail.can_be_sold"
        @change="setRequiredSalePriceOnAllowToSellIsChecked($event)"
      />
    </b-col>
  </b-row>
</template>

<script>
// Components
import { VBTooltip, BCol, BRow } from 'bootstrap-vue'
import config from '@/views/main/inventory/inventoryConfig'
import { ref } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'
import VueTreeSelect from '../VueTreeSelect.vue'
import InventoryProductAutoSuggest from '../InventoryProductAutoSuggest.vue'
import UploadAttachment from './UploadAttachment.vue'

export default {
  name: 'FormFields',
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    ValidationProvider,
    BCol,
    BRow,
    VueTreeSelect,
    UploadAttachment,
    InventoryProductAutoSuggest,
  },
  props: {
    isEditable: {
      type: Boolean,
      require: false,
      default: false,
    },
    isSubmit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inventory() {
      return this.$store.state[this.MODULE_NAME].inventoryForm
    },
    getStockProduct() {
      return this.$store.state[this.MODULE_NAME].inventory
    },
    inventoryISSerialized() {
      return this.$store.state[this.MODULE_NAME].inventorySerializedAndNonSerializedVal
    },
  },
  created() {
    if (this.inventory.allowed_to_sell) {
      this.setRequiredSalePriceOnAllowToSellIsChecked(this.inventory.allowed_to_sell)
    }
  },
  methods: {
    allowedToSell() {
      if (this.inventory.allowed_to_sell) {
        this.$store.commit(`${this.MODULE_NAME}/SET_INVENTORY_FORM`, { ...this.inventory, ...{ active: true } })
      }
    },
    getProps(fieldName) {
      return {
        field: this.updateFields[fieldName],
        name: fieldName,
        class: this.updateFields[fieldName]?.class,
      }
    },
    hasKey(key) {
      // eslint-disable-next-line no-prototype-builtins
      return this.updateFields.hasOwnProperty(key)
    },
    returnTags(tagsArrName) {
      return this.getStockProduct[tagsArrName]?.map(tag => tag?.label)
    },
    setRequiredSalePriceOnAllowToSellIsChecked(event) {
      if (event) {
        this.updateFields.sale_price.rules = 'required'
      } else {
        this.updateFields.sale_price.rules = null
      }
      this.isRequired = event
    },
  },
  setup() {
    const MODULE_NAME = 'inventories'
    const isRequired = ref(false)

    const { updateFields } = config()
    return {
      isRequired,
      updateFields,
      MODULE_NAME,
    }
  },
}
</script>
