<template>
  <!--  <b-modal-->
  <!--    ref="modalOrderPlacedBy"-->
  <!--    title="Order Placed By"-->
  <!--    @ok="onOk"-->
  <!--    @cancel="onCancel"-->
  <!--  >-->
  <b-row>
    <b-col
      cols="6"
      class="assets-static-data"
    >
      <span class="font-medium-4 font-weight-bolder mb-1 d-inline-block">{{ $t(' Inventory Asset Information') }}</span>
      <b-row v-if="inventoryISSerialized">
        <b-col
          cols="6"
        >
          <b>
            {{ $t('Current State') }}
          </b>
        </b-col>
        <b-col
          cols="6"
        >
          <div
            class="d-flex align-items-center"
            style="gap: 8px"
          >
            <state-of-asset
              :data="inventoryProduct"
            />
          </div>
        </b-col>
      </b-row>
      <b-row v-if="inventoryISSerialized">
        <b-col cols="6">
          <b>
            {{ $t('Warehouse of physical asset emplacement') }}
          </b>
        </b-col>
        <b-col
          cols="6"
        >
          {{ inventory.warehouse_location ? inventory.warehouse_location.warehouse.label : '—' }}
        </b-col>
      </b-row>
      <b-row v-if="inventoryISSerialized">
        <b-col cols="6">
          <b>
            {{ $t('Asset Number ') }}
          </b>
        </b-col>
        <b-col
          cols="6"
        >
          {{ getValueFromGivenObjectByKey(inventory, 'id') }}
        </b-col>
      </b-row>
      <b-row v-if="inventoryISSerialized">
        <b-col cols="6">
          <b>
            {{ $t('Date of add to the stock ') }}
          </b>
        </b-col>
        <b-col
          cols="6"
        >
          {{ inventory.created_at ? formatDate(inventory.created_at) : '—' }}
          <!--          convertTZ-->
        </b-col>
      </b-row>
      <b-row v-if="inventoryISSerialized">
        <b-col cols="6">
          <b>
            {{ $t('Purchase Order Number') }}
          </b>
        </b-col>
        <b-col
          cols="6"
        >
          {{ getValueFromGivenObjectByKey(inventory, 'detail.purchase_order_number') }}
        </b-col>
      </b-row>
      <b-row v-if="inventoryISSerialized">
        <b-col cols="6">
          <b>
            {{ $t('Last service Order') }}
          </b>
        </b-col>
        <b-col
          cols="6"
        >
          {{ getValueFromGivenObjectByKey(inventory, 'order_number') }}
        </b-col>
      </b-row>
      <b-row v-if="!inventoryISSerialized">
        <b-col cols="6">
          <b>
            {{ $t('Asset Count') }}
          </b>
        </b-col>
        <b-col
          cols="6"
        >
          {{ getValueFromGivenObjectByKey(inventory, 'amount') }}
        </b-col>
      </b-row>
      <b-row v-if="!inventoryISSerialized">
        <b-col cols="6">
          <b>
            {{ $t('Last Purchase') }}
          </b>
        </b-col>
        <b-col
          cols="6"
        >
          {{ getValueFromGivenObjectByKey(inventory, 'purchase') }}
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="6">
      <span class="font-medium-4 font-weight-bolder mb-1 d-inline-block">{{ $t('Previous Comments') }}</span>
      <div>
        <span class="font-weight-bolder">{{ getValueFromGivenObjectByKey(inventory, 'comments') }}</span>
      </div>

    </b-col>
  </b-row>
<!--  </b-modal>-->
</template>
<script>
// Components
import { VBTooltip, BCol, BRow } from 'bootstrap-vue'
import config from '@/views/main/inventory/inventoryConfig'
import jwtDefaultConfig from '@/auth/jwt/jwtDefaultConfig'
import StateOfAsset from '../StateOfAsset.vue'
import { getValueFromGivenObjectByKey } from '../../../../../../@core/utils/utils'

export default {
  name: 'StaticData',
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCol,
    BRow,
    StateOfAsset,
  },
  data() {
    return {
      theDateFormat: JSON.parse(localStorage.getItem(jwtDefaultConfig.storageOrganizationDateFormat)),
    }
  },
  computed: {
    dateFormatLoaded() {
      return this.$store.state.listModule?.dateformatList.find(format => format.id === this.theDateFormat)
    },
    inventory() {
      return this.$store.state[this.MODULE_NAME].inventory
    },
    inventoryISSerialized() {
      return this.$store.state[this.MODULE_NAME].inventorySerializedAndNonSerializedVal
    },
    inventoryProduct() {
      return this.$store.state[this.MODULE_NAME].inventory
    },
  },
  created() {
    this.$store.dispatch('listModule/dateformatList')
  },
  methods: {
    getValueFromGivenObjectByKey,
    formatDate(date) {
      if (!date) return '-----'
      date = this.convertTZ(date)
      const dateFormat = this.dateFormatLoaded
      if (!dateFormat || !dateFormat.label) return '-----'
      const formattedDate = this.customFormatDate(date, dateFormat.label)
      return formattedDate
    },
    customFormatDate(date, format) {
      const parts = date.split(/[ -]/)

      const day = parts[0]
      const month = parts[1]
      const yearFull = parts[2]
      const yearShort = yearFull.slice(-2)
      const time = date.slice(-13)

      let formattedDate = format
        .replace('dd', day)
        .replace('MM', month)
        .replace('yyyy', yearFull)
        .replace('yy', yearShort)
        .replace('/', '-')
        .replace('/', '-')

      formattedDate = `${formattedDate} ${time}`

      return formattedDate
    },
  },
  setup() {
    const MODULE_NAME = 'inventories'

    const { fieldsNonSerializedAmount } = config()
    return {
      fieldsNonSerializedAmount,
      MODULE_NAME,
    }
  },
}
</script>
