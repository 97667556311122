<template>
  <div class="mb-1">
    <label
      class="font-weight-bolder ml-1"
      for="treeselect"
    >{{ $t('Pick Location') }}</label>
    <validation-provider
      #default="{ errors }"
      name="Pick Location"
      :rules="'required'"
      vid="treeselect"
      class="w-100"
    >

      <treeselect
        id="treeselect"
        v-model="inventory.location"
        class="tree-select--custom"
        :options="inventoryWarehouseLocations"
        :disabled="isEditable"
      >
        <div
          slot="value-label"
          slot-scope="{ node }"
        >
          {{ node.raw.optionLabel }}
        </div>
        <div
          slot="option-label"
          slot-scope="{ node }"
        >
          <button
            :disabled="node.raw.disabled"
            type="button"
            :class="`tree-select--custom--disabled-btn  ${node.raw.disabled ? 'disabled': ''}`"
          >
            {{ node.raw.label }}
            <!--            <feather-icon-->
            <!--              v-if="!node.raw.disabled"-->
            <!--              icon="LBoxSentIcon"-->
            <!--            />-->
          </button>
        </div>
      </treeselect>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'VueTreeSelect',
  components: {
    Treeselect,
    ValidationProvider,
  },
  props: {
    isEditable: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    inventory() {
      return this.$store.state[this.MODULE_NAME].inventoryForm
    },
    inventoryWarehouseLocations() {
      return this.$store.state[this.MODULE_NAME].warehouses?.locations ?? []
    },
  },
  methods: {
    select(e) {
      console.log(e)
    },
  },
  setup() {
    const MODULE_NAME = 'inventories'

    return {
      MODULE_NAME,
    }
  },
}
</script>
<style lang="scss">
.tree-select--custom {
  height: 38px !important;

  &--disabled-btn {
    width: 100%;
    background-color: transparent;
    border: none;
    text-align: left;
    outline: none;
    color: #646464;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  & .vue-treeselect__control {
    height: 100% !important;
    padding: 2px 10px 0 !important;
  }
}
 .tree-select--custom.vue-treeselect--disabled .vue-treeselect__control{
    background-color: #efefef !important;
  }
.tree-select--custom--disabled-btn>svg{
  width: 20px;
  height: 20px;
  fill: none;
  background-image: url('../../../../../assets/images/svg/select.svg');
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid red;
}

</style>
