export default function config() {
  const uploadAttachmentTableCol = [
    {
      label: 'File Name',
      thStyle: {
        width: '25%',
        fontWeight: 'bold',
      },
    },
    {
      label: 'Date',
      thStyle: {
        width: '15%',
        fontWeight: 'bold',
      },
    },
    {
      label: 'Type',
      thStyle: {
        width: '10%',
        fontWeight: 'bold',
      },
    },
    {
      label: 'Category',
      thStyle: {
        width: '20%',
        fontWeight: 'bold',
      },
    },
    {
      label: '',
      thStyle: {
        width: '21%',
        fontWeight: 'bold',
      },
    },
  ]

  return {
    uploadAttachmentTableCol,
  }
}
