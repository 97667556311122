<template>
  <div>
    <label
      for="supplier-name"
      class="font-weight-bolder ml-1"
    >{{ $t('Linked Catalog item') }}</label>
    <div
      class="d-flex"
      style="gap: 8px"
    >
      <vue-autosuggest
        id="supplier-name"
        ref="autocomplete"
        v-model="searchQuery"
        :suggestions="suggestions"
        :input-props="getSearchInputProps()"
        class="flex-fill"
        :render-suggestion="renderSuggestion"
        :get-suggestion-value="getSuggestionValue"
        :is-editable="isEditable"
        @blur="onBlurHandler()"
        @input="fetchResults"
        @selected="selectHandler"
      />
      <b-button
        class="btn-mini"
        variant="secondary"
        style="min-width: 36px; max-width: 36px; height: 36px"
        :disabled="isEditable"
      >
        <feather-icon
          icon="LSearchIcon"
          size="16"
        />
      </b-button>
    </div>
  </div>
</template>

<script>
import { debounce } from '@core/utils/utils'
import '@core/scss/vue/libs/vue-autosuggest.scss'

import {
  BButton,
} from 'bootstrap-vue'
// import { VueAutosuggest } from 'vue-autosuggest'
import VueAutosuggest from '@/views/components/AutoSuggest/Autosuggest.vue'

export default {
  name: 'InventoryProductAutoSuggest',
  components: {
    BButton,
    VueAutosuggest,
  },
  props: {
    isEditable: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  data() {
    return {
      searchQuery: '',
      suggestions: [],
    }
  },
  computed: {
    inventory() {
      return this.$store.state[this.MODULE_NAME].inventoryForm
    },
    // isEditable() {
    //   return this.$store.state[this.MODULE_NAME].isEditable
    // },
  },
  created() {
    if (this.inventory.product) {
      this.searchQuery = this.inventory.product.name
    }
  },
  methods: {
    onBlurHandler() {
      // event fired when clicking on the input
      if (!this.searchQuery) {
        this.inventory.product = null
      }
    },
    getSearchInputProps() {
      const defaultInputProps = {
        id: 'product-search',
        placeholder: 'Search...',
        class: 'form-control',
        name: 'product-search',
      }
      return {
        ...defaultInputProps,
      }
    },
    renderSuggestion(suggestion) {
      return suggestion.item.name
    },

    getSuggestionValue(suggestion) {
      const { item } = suggestion
      return item.name
    },
    fetchResults(searchQuery) {
      this.search(searchQuery, this)
    },
    selectHandler(suggest) {
      this.inventory.product = suggest.item
    },
    search: debounce((searchQuery, ctx) => {
      ctx.getSelectOptions(searchQuery)
    }, 50),

    getSelectOptions(params) {
      this.suggestions = []
      if (params && params.length > 2) {
        this.$store.dispatch(`${this.MODULE_NAME}/getStockProductsForAutoSuggest`, {
          search: params,
          per_page: 50,
        }).then(({ data }) => {
          const mappedData = data.data.data.map(item => ({
            ...item,
            name: item.name,
          }))
          this.suggestions = [{ data: mappedData }]
        })
      }
    },
  },
  setup() {
    const MODULE_NAME = 'inventories'

    return {
      MODULE_NAME,
    }
  },
}
</script>
