<template>
  <div>
    <div
      class="d-flex justify-content-end mb-1"
      style="gap: 12px"
    >
      <b-skeleton
        width="38px"
        height="36px"
        class="mb-0"
      />
      <b-skeleton
        width="38px"
        height="36px"
        class="mb-0"
      />
      <b-skeleton
        width="38px"
        height="36px"
        class="mb-0"
      />
      <b-skeleton
        width="38px"
        height="36px"
        class="mb-0"
      />
      <b-skeleton
        width="38px"
        height="36px"
        class="mb-0"
      />
      <b-skeleton
        width="38px"
        height="36px"
        class="mb-0"
      />
    </div>
    <b-row class="mb-1">
      <b-col cols="6">
        <span class="font-medium-4 font-weight-bolder mb-1 d-inline-block">{{ $t('Inventory Asset Information') }}</span>
        <div
          v-for="item in Array(6)"
          :key="item"
          class="d-flex"
          style="gap: 12px; margin-bottom: 8px"
        >
          <span>
            <b-skeleton
              width="100px"
              class="mb-0"
            />
          </span>
          <span>
            <b-skeleton
              width="100px"
              class="mb-0"
            />
          </span>
        </div>

      </b-col>
      <b-col cols="6">
        <span class="font-medium-4 font-weight-bolder mb-1 d-inline-block">{{ $t('Previous Comments') }}</span>

        <div
          class="d-flex"
          style="gap: 12px; margin-bottom: 8px"
        >
          <span>
            <b-skeleton
              width="100px"
              class="mb-0"
            />
          </span>
          <span>
            <b-skeleton
              width="100px"
              class="mb-0"
            />
          </span>
        </div>

      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <div
          style="height: 70px"
        >
          <span class="inputLabel">{{ 'Pick Location' }}</span>
          <b-skeleton
            height="40px"
            style="border-radius: 5px"
          />
        </div>
        <div
          v-for="field in ['serial_number', 'cost', 'sale_price',]"
          :key="field"
          style="height: 70px"
        >
          <span class="inputLabel">{{ updateFields[field].label }}</span>
          <b-skeleton
            height="40px"
            style="border-radius: 5px"
          />
        </div>
        <span class="inputLabel">{{ 'Status' }}</span>
        <div
          v-for="field in ['active',]"
          :key="field"
          style="height: 40px"
          class="d-flex align-items-center justify-content-between"
        >
          <span class="inputLabel">{{ updateFields[field].label }}</span>
          <b-skeleton
            height="18px"
            width="18px"
            style="border-radius: 5px"
          />
        </div>
      </b-col>
      <b-col md="6">
        <div
          v-for="field in ['tags',]"
          :key="field"
          style="height: 70px"
        >
          <span class="inputLabel">{{ updateFields[field].label }}</span>
          <b-skeleton
            height="40px"
            style="border-radius: 5px"
          />
        </div>
        <div
          v-for="field in ['comments']"
          :key="field"
          style="height:220px"
        >
          <span class="inputLabel">{{ updateFields[field].label }}</span>
          <div
            class="border"
            style="border-radius: 5px; padding: 11px 14px"
          >
            <b-skeleton
              width="60%"
              height="18px"
              style="border-radius: 5px"
            />
            <b-skeleton
              width="80%"
              height="18px"
              style="border-radius: 5px"
            />
            <b-skeleton
              width="100%"
              height="18px"
              style="border-radius: 5px"
            />
            <b-skeleton
              width="90%"
              height="18px"
              style="border-radius: 5px"
            />
            <b-skeleton
              width="80%"
              height="18px"
              style="border-radius: 5px"
            />
          </div>
        </div>
        <span class="inputLabel">{{ 'Sales' }}</span>
        <div
          v-for="field in ['allowed_to_sell',]"
          :key="field"
          style="height: 40px"
          class="d-flex align-items-center justify-content-between"
        >
          <span class="inputLabel">{{ updateFields[field].label }}</span>
          <b-skeleton
            height="18px"
            width="18px"
            style="border-radius: 5px"
          />
        </div>
      </b-col>
    </b-row>
  </div>

</template>

<script>

// Components
import { BSkeleton, BRow, BCol } from 'bootstrap-vue'

// Configs
import config from '@/views/main/inventory/inventoryConfig'

export default {
  name: 'FormSkeleton',
  components: {
    BSkeleton,
    BRow,
    BCol,
  },
  setup() {
    const MODULE_NAME = 'inventories'

    const { updateFields } = config()
    return {
      updateFields,
      MODULE_NAME,
    }
  },
}
</script>
