<template>
  <div
    class="bg-white mb-1"
    style="border-radius: 5px; padding: 12px"
  >
    <portal to="body-top">
      <h6 class="font-medium-4 text-center font-weight-bolder mb-1">
        {{ $t('Asset Details') }}{{ ` — ${inventoryItem.sku} — ${inventoryItem.name}` }}
      </h6>
    </portal>
    <form-skeleton v-if="loading" />
    <inventory-update-form
      v-else
      ref="inventory-update-form"
      @load-data="onLoad"
    />
  </div>
</template>

<script>
import config from '@/views/main/inventory/inventoryConfig'
import { SELECT_INPUT } from '@/views/components/DynamicForm/constants'
import InventoryUpdateForm from '../components/InventoryUpdateForm/InventoryUpdateForm.vue'
import FormSkeleton from '../components/InventoryUpdateForm/FormSkeleton.vue'

export default {
  name: 'InventoryUpdate',
  components: {
    FormSkeleton,
    InventoryUpdateForm,
  },
  data() {
    return {
      loading: false,
      getInventoryItemID: null,
    }
  },
  computed: {
    inventoryItem() {
      return this.$store.state[this.MODULE_NAME].product
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['inventory-update-form']) {
      this.$refs['inventory-update-form'].showConfirmationModal(next)
    } else {
      next()
    }
  },
  created() {
    this.$store.commit(`${this.MODULE_NAME}/SET_IS_EDITABLE`, true)
    this.onLoad()
  },
  methods: {
    onLoad() {
      this.loading = true
      this.$store.commit(`${this.MODULE_NAME}/SET_INVENTORY_ID`, this.$route.params.id)
      this.$store.dispatch('listModule/attachmentCategoryListAssets')
      this.$store.dispatch(`${this.MODULE_NAME}/get`, this.$route.params.inv_id).then(res => {
        const { data } = res.data

        this.getInventoryItemID = data.id

        const mappedData = this.mappingFields(Object.keys(this.fieldsNonSerializedAmount), data, {
          location: data.warehouse_location.id,
          tags: data.tags ? data.tags.map(tag => tag.label) : [],
          amount: data.amount,
          cost: +data.cost,
          sale_price: +data.sale_price,
          attachments: data.attachments,
          active: data.active !== null,
          condition_id: data.inventory_asset_condition_taxonomy,
          comments: data.comments,
          inspected: data.inspected !== null,
          allowed_to_sell: data.allowed_to_sell !== null,
        })

        mappedData.product = data.product ?? {}

        this.$store.commit(`${this.MODULE_NAME}/SET_INVENTORY_FORM`, mappedData)
        this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_INVENTORY_FORM_CLONE`, mappedData)
      }).catch(err => {
        this.error = true
        this.errorNotification(this, err)
      }).finally(() => {
        this.loading = false

        this.$store.dispatch(`${this.MODULE_NAME}/generateInventoryItemBarcode`, this.getInventoryItemID).then(res => {
          this.$store.commit(`${this.MODULE_NAME}/SET_INVENTORY_GENERATED_BARCODE`, res.data.data.html)
        })
      })
      //
    },
  },
  setup() {
    const MODULE_NAME = 'inventories'
    const MODULE_NAME_CLONE = 'cloneData'
    const { fieldsNonSerializedAmount } = config()

    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      fieldsNonSerializedAmount,
    }
  },
}
</script>
