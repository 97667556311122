<template>
  <div>
    <span class="font-weight-bolder">{{ $t("Attachments") }}</span>
    <div class="l-file-upload mb-1">
      <div class="d-flex justify-content-end mb-1">
        <feather-icon
          style="min-width: 37px; min-height: 37px"
          icon="LAddButtonIconWhite"
          size="24"
          :class="`cursor-pointer feather-add-icon ${isEditable ? 'is-editable-file-upload': ''}`"
          @click="showByRef"
        />
      </div>
      <div
        v-if="!verificationAttachments.length"
      >
        <b-table-simple
          :class="`upload-attachment-table ${isEditable ? 'upload-attachment-table--disabled': ''}`"
        >
          <b-thead>
            <b-tr>
              <b-th
                v-for="({label, thStyle}, idx) in uploadAttachmentTableCol"
                :key="idx"
                :style="thStyle"
              >
                {{ label }}
              </b-th>
            </b-tr>
          </b-thead>
        </b-table-simple>
        <div
          class="text-center upload-file__files-list-no-data border mb-md-1"
        >
          {{ $t('None Found') }}
        </div>
      </div>
      <div
        v-else
        class="border"
        style="border-radius: 5px"
      >
        <div
          v-if="isLoading"
          class="d-flex justify-content-center my-2"
        >
          <b-spinner />
        </div>
        <div v-if="!isLoading">
          <b-table-simple
            :class="`upload-attachment-table ${isEditable ? 'upload-attachment-table--disabled': ''}`"
          >
            <b-thead>
              <b-tr>
                <b-th
                  v-for="({label, thStyle}, idx) in uploadAttachmentTableCol"
                  :key="idx"
                  :style="thStyle"
                >
                  {{ label }}
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="(file, index) in verificationAttachments"
                :key="index"
              >
                <b-th>
                  <span
                    v-b-tooltip.noninteractive.hover
                    class="file-name"
                    :title="file.id ? file.name : file.file.name"
                  >
                    {{ file.id ? file.name : file.file.name }}
                  </span>
                </b-th>
                <b-th>
                  <span
                    v-b-tooltip.noninteractive.hover
                    :title="file.id ? convertTZ(file.created_at) : '---'"
                    class="file-name"
                  >
                    {{ file.id ? convertTZ(file.created_at) : '---' }}
                  </span>
                </b-th>
                <b-th>
                  <span
                    v-b-tooltip.noninteractive.hover
                    class="file-name"
                    :title="file.id ? file.mime_type : file.file.type"
                  >
                    {{ file.id ? file.mime_type : file.file.type }}
                  </span>
                </b-th>
                <b-th>
                  <span
                    v-b-tooltip.noninteractive.hover
                    class="file-name"
                    :title="getCategoryName(file.attachment_category_id)"
                  >
                    {{ getCategoryName(file.attachment_category_id) }}
                  </span>
                </b-th>
                <b-th>
                  <div class="d-flex justify-content-end">
                    <div class="upload-file__files-list-item-del">
                      <feather-icon
                        icon="LTrashIcon"
                        size="28"
                        style="padding: 5px"
                        class="cursor-pointer mr-1 border-dotted feather-trash-icon"
                        @click="deleteAttachment(file.id, index)"
                      />
                    </div>
                    <div class="d-flex">
                      <span>
                        <span>
                          <feather-icon
                            v-if="file.id"
                            icon="LDownloadIcon"
                            size="28"
                            style="padding: 5px"
                            class="cursor-pointer border-dotted feather-download-icon mr-1"
                            @click="uploadAttachment(file, index)"
                          />
                        </span>
                      </span>
                      <span>
                        <a @click="showAttachment(file)">
                          <feather-icon
                            icon="LEyeIcon"
                            size="28"
                            style="padding: 5px"
                            class="cursor-pointer border-dotted feather-eye-icon defaultIconColorTwo"
                          />
                        </a>
                      </span>
                    </div>
                  </div>
                </b-th>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>

      <!-- ===================================== -->
      </div>
      <div
        :class="`l-file-upload ${isEditable ? 'upload-attachment-table--disabled': ''}`"
      >
        <l-file-upload
          ref="refLFileUploadInput"
          v-model="chosenFiles"
          :is-editable="ACCESS_ABILITY_FOR_CONTACTS"
          :do-disabled="isEditable"
          :accept-extension-array="['.jpg', '.jpeg', '.png', '.tif', '.pdf', '.bmp']"
          @input="showModalToChoseCategory"
        />
        <b-modal
          id="attachment-create-modal"
          centered
          hide-header
          body-class="l-modal__body"
          footer-class="l-modal-footer"
          ok-title="Upload"
          ok-variant="success"
          :ok-disabled="isOkUploadBtnActive"
          :cancel-disabled="isCancelUploadBtnActive"
          cancel-variant="outline-primary"
          @hidden="clearData"
          @ok="uploadFile"
        >
          <b-overlay :show="isUploading">
            <div class="l-modal__container">
              <b-row>
                <b-col cols="12">
                  <h3 class="l-modal__title">
                    {{ $t('What category is this file?') }}
                  </h3>
                </b-col>
                <b-col>
                  <l-select
                    v-model="sendData.attachment_category_id"
                    name="attachment_category_id"
                    :field="{
                      options: { transition: '' },
                      store: 'attachmentCategoryListAssets',
                    }"
                  />
                </b-col>
              </b-row>
            </div>
          </b-overlay>
        </b-modal>
      </div>
    </div></div></template>
<script>
import {
  BRow,
  BCol,
  BThead,
  BTr,
  BTh,
  BModal,
  BOverlay,
  BSpinner,
  BTableSimple,
  BTbody,
  VBTooltip,
} from 'bootstrap-vue'
import ToastificationContentVue from '@core/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'
import axios from 'axios'

import lFileUpload from '@/views/main/customers/view/attachments/LFileUpload.vue'
import config from './config'
import mainConfig from '../../../inventoryConfig'

export default {
  name: 'UploadAttachment',
  components: {
    lFileUpload,
    BRow,
    BCol,
    BThead,
    BTr,
    BTh,
    BModal,
    BOverlay,
    BTbody,
    BSpinner,
    BTableSimple,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    value: {},
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    acceptExtensionArray: {
      type: Array,
      required: false,
      default: () => [],
    },
    isEditable: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      uploadedFiles: [],
      attachments: [],
      isUploading: false,
      isLoading: false,
      isMovingToTrash: false,
      inputValue: null,
      fileName: null,
      chosenFiles: null,
      moveToTrashItemId: null,
      allowedFormats: ['jpg', 'jpeg', 'png', 'tif', 'pdf', 'bmp'],
      sendData: {
        attachment_category_id: null,
        model_type: 1,
        file: null,
      },
    }
  },
  computed: {
    formattedAcceptProps() {
      return this.acceptExtensionArray
    },
    verificationAttachments() {
      console.log(this.$store.state[this.MODULE_NAME].inventoryForm.attachments)
      return this.$store.state[this.MODULE_NAME].inventoryForm.attachments ?? []
    },
    attachmentCategoryListAssets() {
      return this.$store.state.listModule.attachmentCategoryListAssets ?? []
    },
    isOkUploadBtnActive() {
      return this.isUploading || !this.sendData.attachment_category_id
    },
    isCancelUploadBtnActive() {
      return this.isUploading
    },
    isOkTrashBtnActive() {
      return this.isUploading
    },
    isCancelTrashBtnActive() {
      return this.isUploading
    },
    files() {
      return this.uploadedFiles.map(file => ({
        name: file.file.name,
      }))
    },
  },
  methods: {
    showModalToChoseCategory() {
      this.$bvModal.show('attachment-create-modal')
    },
    hideModalToChoseCategory() {
      this.$bvModal.hide('attachment-create-modal')
    },
    showAttachment(file) {
      if (file.id) {
        window.open(file.url, '_blank')
      } else {
        console.log(file)
        window.open(
          (window.URL || window.webkitURL).createObjectURL(file.file),
          '_blank',
        )
      }
    },
    showByRef() {
      this.$refs.refLFileUploadInput.clickFileInput()
    },
    fileInputChanged(e) {
      this.chosenFiles = e.target.files
      this.showModalToChoseCategory()
    },
    clearData() {
      this.sendData.attachment_category_id = null
      this.sendData.file = null
      this.chosenFiles = null
      this.$refs.refLFileUploadInput.value = null
    },
    mapDataObject() {
      return {
        ...this.sendData,
        attachment_category_id: this.sendData.attachment_category_id.id,
        file: this.chosenFiles[0],
      }
    },
    getCategoryName(id) {
      return this.attachmentCategoryListAssets.find(item => +item.id === +id)?.name ?? 'no category adw aw daw daw d awd awd'
    },
    postData() {
      this.isUploading = false
      this.hideModalToChoseCategory()
    },
    checkTheFileType(file) {
      const fileType = file.split('/')[1]
      let isErrorAvailable = false
      if (!this.allowedFormats.includes(fileType)) {
        isErrorAvailable = true
        this.$bvToast.toast(this.$t('File format is not accepted'), {
          variant: 'danger',
          solid: true,
          title: this.$t('Error'),
        })
        this.postData()
      }
      return isErrorAvailable
    },
    uploadFile() {
      const file = this.chosenFiles[0]
      if (this.checkTheFileType(file.type)) {
        return
      }
      if (file.size > 4 * 1024 * 1024) {
        // 4MB limit
        this.postData()
        this.$bvToast.toast(this.$t('File size exceeds the limit of 4MB. Please choose a smaller file'), {
          variant: 'danger',
          solid: true,
          title: this.$t('Error'),
        })
        return
      }
      this.isUploading = true
      this.verificationAttachments.push(this.mapDataObject())
      this.attachments.push(this.mapDataObject())
      this.postData()
    },
    deleteAttachment(id, index) {
      if (id) {
        this.isLoading = true
        this.$store
          .dispatch(`${this.MODULE_NAME}/deleteAttachment`, { id })
          .catch(() => {
            this.isLoading = false
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: i18n.t('Error Deleting This file', {
                  module: this.MODULE_NAME,
                }),
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
          .finally(() => {
            this.$store.dispatch(`${this.MODULE_NAME}/get`, this.$route.params.inv_id).then(res => {
              const { data } = res.data
              const mappedData = this.mappingFields(Object.keys(this.fieldsNonSerializedAmount), data, {
                location: data.warehouse_location.id,
                tags: data.tags ? data.tags.map(tag => tag.label) : [],
                amount: data.amount,
                cost: +data.cost,
                sale_price: +data.sale_price,
                attachments: data.attachments,
                active: data.active,
              })

              mappedData.product = data.product ?? {}

              this.$store.commit(`${this.MODULE_NAME}/SET_INVENTORY_FORM`, mappedData)
              this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_INVENTORY_FORM_CLONE`, mappedData)
            }).catch(err => {
              this.error = true
              this.errorNotification(this, err)
            }).finally(() => {
              this.isLoading = false
            })
          })
      } else {
        this.verificationAttachments.splice(index, 1)
      }
    },
    uploadAttachment(file) {
      console.log(file)
      if (file) {
        this.show = true
        const fileUrl = file.url
        axios({
          url: fileUrl,
          method: 'GET',
          responseType: 'blob',
        }).then(res => {
          const fileBlob = window.URL.createObjectURL(new Blob([res.data]))
          const fileLink = document.createElement('a')
          fileLink.href = fileBlob
          const fileName = fileUrl.split('/').pop()
          fileLink.setAttribute('download', fileName)
          fileLink.setAttribute('id', 'test-download')
          // fileLink.setAttribute('target', '_blank')
          document.body.appendChild(fileLink)
          fileLink.click()
        }).catch(() => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: i18n.t('Error Uploading This file', {
                module: this.MODULE_NAME,
              }),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          this.show = false
        })
      }
    },

  },
  setup() {
    const MODULE_NAME = 'inventories'
    const MODULE_NAME_CLONE = 'cloneData'
    const ACCESS_ABILITY_FOR_CONTACTS = { action: 'change', subject: 'Contacts' }
    const { uploadAttachmentTableCol } = config()
    const { fieldsNonSerializedAmount } = mainConfig()

    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      ACCESS_ABILITY_FOR_CONTACTS,
      uploadAttachmentTableCol,
      fieldsNonSerializedAmount,
    }
  },
}
</script>
<style lang="scss" scoped>
.is-editable-file-upload {
  opacity: 0.5;
  pointer-events: none;
}

.upload-attachment-table {
  margin-bottom: 0;

  th {
    padding: 5px !important;
    font-weight: normal;
  }

  & .file-name,
  & .file-data {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 24px;
  }

  &--disabled{
    pointer-events: none;
    opacity: 0.5;
  }

}
</style>
